<template>
  <div class="newsletter" :class="addClass">

    <load-script v-if="!!siteKey" src="https://www.google.com/recaptcha/api.js?onload=onCaptchaAPIReady&render=explicit"/>

    <slot name="title"/>
    <form action="" method="post" @submit.prevent="validateAndSend">
 
      <div class="newsletter__input" v-if="Object.keys(dataFirstname).length!==0">
         <label v-show="showFirstNamePlaceholder"
               for="newsletter-firstname"
               :class="{ 'fixed': isFixedPlaceholder }"
               ref="firstNameLable">{{ dataFirstname.placeholder }}</label>
        <input
          v-model="firstName"
          type="text"
          :id="dataFirstname.name"
          @blur="firstNameValidation()"
          :class="{ 'input-error': firstNameError  }"
          :aria-describedby="fNameDescribedby"
          :name="dataFirstname.name"> 
        <p id="newsletter-email-error" v-if="firstNameError"  class="newsletter__error">{{ dataFirstname.errormsg }}</p>
      </div>
      <div class="newsletter__input" v-if="Object.keys(dataSurname).length!==0">
        <label v-show="showSurnNamePlaceholder"
               for="newsletter-surname"
               :class="{ 'fixed': isFixedPlaceholder }"
               ref="surNameLabel">{{ dataSurname.placeholder }}</label>
        <input
          v-model="surName"
          type="text"
          :id="dataSurname.name"
          @blur="surNameValidation()"
          :class="{ 'input-error': surNameError}"
          :aria-describedby="surNameDescribedby"
          :name="dataSurname.name"> 
        <p id="newsletter-email-error" v-if="surNameError"  class="newsletter__error">{{ dataSurname.errormsg}}</p>
        
      </div>
      <div class="newsletter__input">
        <label v-show="showemailPlaceholder"
               for="newsletter-email"
               :class="{ 'fixed': isFixedPlaceholder }"
               ref="emailLabel">{{ emailPlaceholder }}</label>
        <input
          v-model="emailField"
          @blur="checkEmailValid(), isEmailEmpty()"
          type="email"
          id="newsletter-email"
          :class="{ 'input-error': hasErrorValidEmail || hasErrorEmail }"
          :aria-describedby="emailDescribedby"
          name="newsletter-email">
        <p v-if="hasErrorValidEmail" id="newsletter-email-error" class="newsletter__error">{{ validationMsg.emailFormat }}</p>
        <p v-if="hasErrorEmail" id="newsletter-email-error" class="newsletter__error">{{ validationMsg.emailEmpty }}</p>
      </div>

      <div class="newsletter__select" id="newsletterSelectComponent">
        <div class="newsletter__select_box">
          <select v-model="countryField" name="newsletterSelect" id="newsletterSelect"/>
          <p id="newsletter-country-error" class="newsletter__error">{{ validationMsg.dataSelected }}</p>
        </div>
      </div>
      <div class="newsletter__input-personal" v-if="dataPersonaldataEnable"><span  v-html="dataPersonaldataRich"></span></div>
      <div class="newsletter__terms">
        <div class="newsletter__checkbox">
          <input v-if="disableToggle =='False'"
            id="terms-of-use"
            class="is-sr-only"
            v-model="termsField"
            type="checkbox"
            :aria-describedby="termsDescribedby"
            name="terms-of-use" >
          <label v-if="disableToggle =='False' " for="terms-of-use">
            <slot name="terms"/>
          </label>
          <span class="disable-checkbox" v-if="disableToggle =='True'">
            <slot name="terms"/>
          </span>
        </div>
        <p v-if="hasErrorTerms" id="terms-of-use-error" class="newsletter__error">{{ validationMsg.terms }}</p>
      </div>

      <div class="newsletter__captcha" v-if="!!siteKey">
        <div id="letter-recaptcha" class="g-recaptcha"/>
        <p v-if="hasErrorCapcha" class="newsletter__error">{{ validationMsg.captcha }}</p>
      </div>

      
        <button type="submit"
                v-if="serverResponse === null"
                :disabled="!isSumbitEnabled"
                :class="[ctaClass, { 'disabled': !isSumbitEnabled}]"
                 v-tag:useractionevent="{
                      'category': 'registration',
                      'action': 'newsletter', 
                      'label': 'body',
                      'event_name': 'newsletter_registration',
                      'referral': 'body' }">
                      <span class="btn__wrapper">{{ submitBtnText }}</span>
         </button>
    
      <div role="alert">
        <p v-if="serverResponse !== null"
           class="newsletter__response"
           :class="{ 'error': !serverResponse }">{{ confirmMsg }}</p>
      </div>
    </form>
  </div>
</template>
<script>
  /* eslint-disable no-undef */
  import { tag } from '@Foundation/analyticsHandler';
  import { postData } from '@Foundation/services';
  import LoadScript from '@Foundation/loadscript/loadscript';

  export default {
    name: 'newsletter',

    directives: {
      tag,
    },

    components: {
      LoadScript
    },

    props: {
      addClass: { type: String, default: '' },
      apiUrl: { type: String, default: '' },
      successMsg: { type: String, default: '' },
      countryCode: { type: String, default: '' },
      errorMsg: { type: String, default: '' },
      siteKey: { type: String, default: '' },
      emailPlaceholder: { type: String, default: '' },
      submitBtnText: { type: String, default: '' },
      disableToggle: { type: String, default: 'True' },
      validationMsg: { type: Object, default: () => {
        return {
          emailFormat: 'Please use valid email format.',
          emailEmpty: 'Please enter your email address.',
          terms: 'Please accept the terms of use.',
          captcha: 'Please check the captcha',
          dataSelected: 'Please select the country'
        };
      } },
      ctaClass: {type: String, default: 'btn btn--black'},
      dataFirstname:{type:Object},
      dataSurname:{type:Object},
      dataPersonaldataRich:{ type: String, default: '' },
      dataPersonaldataEnable:{ type: Boolean}
    },

    data() {
      return {
        isSumbitEnabled: false,
        serverResponse: null,
        emailField: '',
        showemailPlaceholder: true,
        countryField: '',
        termsField: false,
        hasErrorValidEmail: false,
        hasErrorEmail: false,
        hasErrorTerms: false,
        hasErrorCapcha: false,
        countryCodeData: this.countryCode,
        news: '',
        captchaResponse: '',
        captchaValue: '',
        firstName:'',
        surName:'',
        firstNameError:false,
        surNameError:false,
        showFirstNamePlaceholder : true,
        showSurnNamePlaceholder : true,
        };
    },

    computed: {
      confirmMsg() {
        return this.serverResponse ? this.successMsg : this.errorMsg;
      },
      fetchUrl() {
        return `${window.location.origin}/${this.apiUrl}`;
      },
      canSubmit() {
        var str = this.countryCode;
        var strArr = str.split(',');
        if (strArr.length > 1) {
          return !!this.emailField && !this.hasErrorValidEmail && this.termsField && this.countryField;
        } else {
          if(Object.keys(this.dataSurname).length === 0){
            this.surName =true;
          }
          if(Object.keys(this.dataFirstname).length === 0){
            this.firstName =true;
          }
         return !!this.emailField && !this.hasErrorValidEmail && this.termsField  && !!this.surName && !!this.firstName;
        }
      },
      isFixedPlaceholder() {
        return (!!this.emailField && this.hasErrorValidEmail) || (this.emailField !== '' && !this.hasErrorValidEmail && !this.hasErrorEmail) || (this.emailField !== '' && !this.hasErrorValidEmail);
      },
      emailDescribedby() {
        if (this.hasErrorValidEmail || this.hasErrorEmail) {
          return 'newsletter-email-error';
        }
        return false;
      },
      surNameDescribedby(){
        if (this.surNameError) {
          return true;
        }
        return false;
      },
      fNameDescribedby(){
        if (this.firstNameError) {
          return true;
        }
        return false;
      },

      termsDescribedby() {
        if (this.hasErrorTerms) {
          return 'terms-of-use-error';
        }
        return false;
      },
         },

    watch: {
      emailField() {
        if (this.emailField.length !== 0) {
          this.showemailPlaceholder = false;
        } else {
          this.showemailPlaceholder = true;
        }
      },
      canSubmit(value) {
        this.isSumbitEnabled = value;
      },
      termsField() {
        this.checkTermsValid();
      },
      surName(){
        
        if(this.surName.length !==0){
          this.showSurnNamePlaceholder=false;
        }else{
          this.showSurnNamePlaceholder=true;
        }
 
      },
      firstName(){
          this.showFirstNamePlaceholder=true;
          if(this.firstName.length !==0){
            this.showFirstNamePlaceholder=false;
          }        
      },
    },
    created() {
      this.captchaResponse = localStorage.captchaResponse;
      window.onCaptchaAPIReady = this.onCaptchaAPIReady;
    },
    mounted() {
      var str = this.countryCode;
      var strArr = str.split(',');
      var newsletterSelect = '';
      for (var i = 0; i < strArr.length; i++) {
        if (i === 0) {
          newsletterSelect += "<option value='' selected>" + strArr[i] + '</option>';
        } else {
          newsletterSelect += "<option value='" + strArr[i] + "'>" + strArr[i] + '</option>';
        }
      }
      document.getElementById('newsletterSelect').innerHTML = newsletterSelect;
      if (strArr.length <= 1 || strArr.length <= 'null') {
        var element = document.getElementById('newsletterSelectComponent');
        element.parentElement.removeChild(element);
      }

      let newsletterCountryError = document.getElementById('newsletter-country-error');
      if(newsletterCountryError && newsletterCountryError !== undefined && newsletterCountryError !== '') {
        newsletterCountryError.style.display = 'none';
        document.getElementById('newsletterSelect').addEventListener('change', function() {
          if (this.value === '') {
            newsletterCountryError.style.display = 'block';
          } else {
            newsletterCountryError.style.display = 'none';
          }
        });
      }
    },
    methods: {
      onCaptchaAPIReady() {
        /* istanbul ignore next */
        setTimeout(() => {
          if (typeof grecaptcha === 'undefined' && typeof grecaptcha.render !== 'function') {
            this.onCaptchaAPIReady();
          } else {
            grecaptcha.render('letter-recaptcha', {
              sitekey: this.siteKey,
              callback: this.checkCaptcha
            });
          }
        }, 200);
      },
      checkCaptcha() {
        /* istanbul ignore next */
        grecaptcha.getResponse().length !== 0 ? this.hasErrorCapcha = false : this.hasErrorCapcha = true;
        this.captchaValue = grecaptcha.getResponse()
      },
      isEmailEmpty() {
        if (this.emailField === '') {
          this.hasErrorEmail = true;
          if(this.disableToggle === 'True'){
          this.isSumbitEnabled = true;
        }
        } else {
          this.hasErrorEmail = false;
        }
      },
      validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      checkEmailValid() {
        if (!this.validateEmail(this.emailField) && this.emailField !== '') {
          this.hasErrorValidEmail = true;
        } else {
          this.hasErrorValidEmail = false;
        }
        if(this.disableToggle === 'True'){
          this.isSumbitEnabled = true;
        }
      },
       surNameValidation(){
        if(this.dataSurname.mandatory === true){
          if(this.surName !== ''){
            this.surNameError=false;
          }else{
              this.surNameError=true;
          }
         if(this.disableToggle === 'True'){
              this.isSumbitEnabled=true;              
          }         
        }else{
              this.surNameError=false;
             if(this.disableToggle === 'True'){
              this.isSumbitEnabled=true;
              
             }
        }
      },
      firstNameValidation(){
        if(this.dataFirstname.mandatory === true){
          if(this.firstName !== ''){
            this.firstNameError=false;
          }else{
              this.firstNameError=true;
          }
         if(this.disableToggle === 'True'){
              this.isSumbitEnabled=true;              
          }
        }else{
              this.firstNameError=false;
             if(this.disableToggle === 'True'){
              this.isSumbitEnabled=true;
              
             }
        }
        
      },
     
      checkTermsValid() {
        if (!this.termsField) {
          this.hasErrorTerms = true;
        } else {
          this.hasErrorTerms = false;
        }
      },
      sendEmail(email,country) {
        const compThis = this;
        return postData(this.fetchUrl, {
          'email': email,
          'captchaResponse': compThis.captchaValue,
          'country': country,
          'firstName': compThis.firstName,
          'lastName':compThis.surName

        });
      },
      validateAndSend() {
        this.isEmailEmpty();
        this.firstNameValidation();
        this.surNameValidation();
       if(this.disableToggle === 'False'){
        this.checkTermsValid();
       }

        if (!!this.siteKey) {
          this.checkCaptcha();

          /* istanbul ignore else */
          if (!!this.emailField && !this.hasErrorCapcha) {
            this.isSumbitEnabled = false;
            this.sendEmail(this.emailField, this.countryField)
              .then(response => this.serverResponse = response);
          }
        } else {
          /* istanbul ignore else */
          if (!!this.emailField ) {
            this.isSumbitEnabled = false;
            this.sendEmail(this.emailField, this.countryField)
              .then(response => this.serverResponse = response);
          }
        }

      },
       
    }
  };
</script>
<style lang='scss' src="./newsletter.scss"></style>
